import React from 'react';

export function PixIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Ilustração do pix</title>
      <path d="M14.578 1.073a3.646 3.646 0 0 0-5.156 0L4.85 5.645h.433c.942 0 1.827.367 2.494 1.033l3.6 3.6a.67.67 0 0 0 .945 0l3.613-3.614a3.505 3.505 0 0 1 2.493-1.032h.71l-4.56-4.559Zm.283 4.517L11.849 8.6l-2.998-3a5.053 5.053 0 0 0-1.02-.788l2.666-2.665a2.128 2.128 0 0 1 3.006 0L16.056 4.7a5.01 5.01 0 0 0-1.195.889ZM18.428 18.378a3.506 3.506 0 0 1-2.493-1.032l-3.613-3.613a.686.686 0 0 0-.946 0l-3.6 3.6a3.508 3.508 0 0 1-2.493 1.032H4.85l4.572 4.572a3.646 3.646 0 0 0 5.156 0l4.56-4.559h-.71Zm-4.925 3.485c-.4.4-.934.621-1.503.621-.568 0-1.103-.22-1.503-.621L7.83 19.198c.37-.217.71-.48 1.02-.79l2.998-2.998 3.012 3.012a5 5 0 0 0 1.195.888l-2.553 2.553Z" fill="#000" fillOpacity=".96" />
      <path d="m20.196 6.69 2.736 2.737a3.646 3.646 0 0 1 0 5.157l-2.736 2.736h-1.768c-.65 0-1.285-.264-1.744-.723l-3.612-3.613c-.654-.652-1.792-.652-2.445.001l-3.6 3.6a2.482 2.482 0 0 1-1.744.722H3.791l-2.723-2.723a3.646 3.646 0 0 1 0-5.157L3.79 6.703h1.492c.65 0 1.285.264 1.744.723l3.6 3.6c.337.337.78.506 1.222.506.443 0 .885-.169 1.221-.505l3.614-3.614a2.482 2.482 0 0 1 1.744-.722h1.768Zm-.63 9.11 2.292-2.29c.401-.402.622-.936.622-1.505 0-.568-.22-1.102-.622-1.503L19.567 8.21h-1.139a.952.952 0 0 0-.669.277l-3.517 3.518 3.517 3.518a.95.95 0 0 0 .669.277h1.139Zm-14.283-.013c.25 0 .493-.101.669-.278l3.505-3.504-3.504-3.504a.952.952 0 0 0-.67-.277h-.862l-2.278 2.278a2.13 2.13 0 0 0 0 3.007l2.278 2.278h.862Z" fill="#000" fillOpacity=".96" />
    </svg>
  );
}
